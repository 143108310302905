const tl = gsap.timeline();
if (!isMobile) {
  gsap.set(sections[0].querySelectorAll('.h-video__mask'), {
    height: '100%',
  });
  gsap.set(sections[0].querySelector('.h-video__line'), {
    autoAlpha: 1,
  });
  gsap.set(sections[0].querySelector('.h-video > span'), {
    y: '200%',
    autoAlpha: 0,
  });
  gsap.set(document.querySelector('.header__logo'), {
    x: '-200%',
    autoAlpha: 0,
  });
  gsap.set(document.querySelector('.header__burger'), {
    x: '200%',
    autoAlpha: 0,
  });
  gsap.set(document.querySelector('.header__toggle'), {
    x: '200%',
    autoAlpha: 0,
  });

  tl.to(sections[0].querySelectorAll('.h-video__mask'), {
    height: '0',
    duration: 0.8,
    delay: 0.8,
  })
    .to(sections[0].querySelector('.h-video__line'), {
      autoAlpha: 0,
      duration: 0.8,
      delay: 0.1,
    })
    .to(sections[0].querySelector('.h-video > span'), {
      y: '0',
      duration: 0.6,
      autoAlpha: 1,
    })
    .to(
      document.querySelector('.header__logo'),
      {
        x: '0',
        duration: 0.6,
        autoAlpha: 1,
      },
      '<'
    )
    .to(
      document.querySelector('.header__burger'),
      {
        x: '0',
        duration: 0.6,
        autoAlpha: 1,
      },
      '<'
    )
    .to(
      document.querySelector('.header__toggle'),
      {
        x: '0',
        duration: 0.6,
        autoAlpha: 1,
      },
      '<'
    );
}

function animateSection(sectionIndex, reverse = false) {
  return new Promise((resolve) => {
    const timeline = gsap.timeline({
      onStart: () => {
        if (!isMobile) {
          if (!reverse) {
            // If scrolling down, make the next section visible and bring it to the front
            if (sections[sectionIndex]) {
              gsap.set(sections[sectionIndex], { autoAlpha: 1, zIndex: 2 });
            }
            // Send the current section to the back
            if (sections[current]) {
              gsap.set(sections[current], { zIndex: 1 });
            }
          } else {
            // If scrolling up, make the current section visible and bring it to the front
            if (sections[current]) {
              gsap.set(sections[current], { autoAlpha: 1, zIndex: 2 });
            }
            // Send the next section to the back
            if (sections[sectionIndex]) {
              gsap.set(sections[sectionIndex], { zIndex: 1 });
            }
          }

          if (sectionIndex === 0.5) {
            gsap.set(sections[1], { autoAlpha: 1, zIndex: 1 });
            gsap.set(sections[0], { autoAlpha: 1, zIndex: 1 });
          }

          if (sectionIndex === 2.5) {
            gsap.set(sections[3], { autoAlpha: 1, zIndex: 1 });
            gsap.set(sections[2], { autoAlpha: 1, zIndex: 2 });
          }

          if (sectionIndex === 4.5) {
            if (!reverse) {
              gsap.set(sections[5], { autoAlpha: 1, zIndex: 1 });
              gsap.set(sections[4], { autoAlpha: 1, zIndex: 2 });
            } else {
              gsap.set(sections[4], { autoAlpha: 1, zIndex: 2 });
              gsap.set(sections[3], { autoAlpha: 1, zIndex: 1 });
            }
          }

          if (sectionIndex === 6.5) {
            if (reverse) {
              gsap.set(sections[6], { autoAlpha: 1, zIndex: 2 });
              gsap.set(sections[5], { autoAlpha: 1, zIndex: 1 });
            }
          }

          if (sectionIndex === 8.5) {
            gsap.set(sections[9], { autoAlpha: 1, zIndex: 1 });
            gsap.set(sections[8], { autoAlpha: 1, zIndex: 2 });
          }
        }
      },
      onComplete: () => {
        if (!isMobile) {
          if (!reverse) {
            // If scrolling down, hide the current section
            if (sections[current]) {
              if (current === 0) {
                setTimeout(() => {
                  gsap.set(sections[0], { autoAlpha: 0, zIndex: 1 });
                }, 100);
              } else if (current === 2) {
                setTimeout(() => {
                  gsap.set(sections[2], { autoAlpha: 0, zIndex: 1 });
                }, 100);
              } else {
                gsap.set(sections[current], { autoAlpha: 0, zIndex: 1 });
              }
            }
          } else {
            // If scrolling up, hide the next section
            if (sections[sectionIndex]) {
              gsap.set(sections[sectionIndex], { autoAlpha: 0, zIndex: 1 });
            }

            if (sectionIndex === 0.5) {
              gsap.set(sections[1], { autoAlpha: 1, zIndex: 1 });
              gsap.set(sections[0], { autoAlpha: 1, zIndex: 2 });
            }

            if (sectionIndex === 4.5) {
              gsap.set(sections[4], { autoAlpha: 0, zIndex: 1 });
              gsap.set(sections[3], { autoAlpha: 1, zIndex: 1 });
            }

            if (sectionIndex === 6.5) {
              gsap.set(sections[6], { autoAlpha: 0, zIndex: 1 });
              gsap.set(sections[5], { autoAlpha: 1, zIndex: 1 });
            }
          }
        }

        listening = true;
        current = sectionIndex;
        resolve();
      },
    });
    const animations = {
      0: () => {
        timeline.to(sections[sectionIndex], {
          onStart: () => {
            if (!isMobile) {
              gsap.set(
                [
                  document.querySelector('.header__logo'),
                  document.querySelector('.header__toggle'),
                ],
                {
                  autoAlpha: 1,
                }
              );
            }

            if (!isMobile) {
              const tl = gsap.timeline();
              if (!reverse) {
                gsap.set(
                  sections[sectionIndex].querySelector('.h-video__content'),
                  {
                    scale: 1,
                  }
                );
                gsap.set(
                  sections[sectionIndex].querySelectorAll('.h-video__mask'),
                  {
                    height: '100%',
                  }
                );
                gsap.set(
                  sections[sectionIndex].querySelector('.h-video__line'),
                  {
                    autoAlpha: 1,
                  }
                );
                gsap.set(
                  sections[sectionIndex].querySelector('.h-video > span'),
                  {
                    y: '200%',
                    autoAlpha: 0,
                  }
                );
                gsap.set(document.querySelector('.header__logo'), {
                  x: '-200%',
                  autoAlpha: 0,
                });
                gsap.set(document.querySelector('.header__burger'), {
                  x: '200%',
                  autoAlpha: 0,
                });
                gsap.set(document.querySelector('.header__toggle'), {
                  x: '200%',
                  autoAlpha: 0,
                });
              }

              tl.to(sections[sectionIndex].querySelectorAll('.h-video__mask'), {
                height: '0',
                duration: 0.8,
                delay: 0.3,
              })
                .to(sections[sectionIndex].querySelector('.h-video__line'), {
                  autoAlpha: 0,
                  duration: 0.8,
                })
                .to(sections[sectionIndex].querySelector('.h-video > span'), {
                  y: '0',
                  duration: 0.6,
                  autoAlpha: 1,
                })
                .to(
                  document.querySelector('.header__logo'),
                  {
                    x: '0',
                    duration: 0.6,
                    autoAlpha: 1,
                  },
                  '<'
                )
                .to(
                  document.querySelector('.header__burger'),
                  {
                    x: '0',
                    duration: 0.6,
                    autoAlpha: 1,
                  },
                  '<'
                )
                .to(
                  document.querySelector('.header__toggle'),
                  {
                    x: '0',
                    duration: 0.6,
                    autoAlpha: 1,
                  },
                  '<'
                );

              const totalDuration = tl.duration();
              timeline.to(sections[sectionIndex], {
                duration: totalDuration,
              });
            }
          },
        });
      },
      0.5: () => {
        timeline.to(sections[1], {
          onStart: () => {
            const tl = gsap.timeline();
            if (!isMobile) {
              gsap.set(sections[0].querySelector('.h-video__content'), {
                scale: 1,
              });
              gsap.set(sections[0].querySelectorAll('.h-video__mask'), {
                height: '100%',
              });
              gsap.set(sections[0].querySelector('.h-video__line'), {
                autoAlpha: 1,
              });
              gsap.set(sections[0].querySelector('.h-video > span'), {
                y: '200%',
                autoAlpha: 0,
              });
              gsap.set(document.querySelector('.header__logo'), {
                x: '-200%',
                autoAlpha: 0,
              });
              gsap.set(document.querySelector('.header__burger'), {
                x: '200%',
                autoAlpha: 0,
              });
              gsap.set(document.querySelector('.header__toggle'), {
                x: '200%',
                autoAlpha: 0,
              });

              tl.to(sections[1].querySelector('#rect-left'), {
                scaleY: 0,
                duration: 0.8,
              })
                .to(
                  sections[1].querySelector('#rect-right'),
                  {
                    scaleY: 0,
                    duration: 0.8,
                  },
                  '<'
                )
                .to(
                  sections[1].querySelector('.groupe__content'),
                  {
                    opacity: 0,
                  },
                  '<'
                );
            }

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      1: () => {
        timeline.to(sections[sectionIndex], {
          onStart: () => {
            if (!isMobile) {
              gsap.set(
                [
                  document.querySelector('.header__logo'),
                  document.querySelector('.header__toggle'),
                ],
                {
                  autoAlpha: 0,
                }
              );
            }
            const tl = gsap.timeline();
            gsap.set(sections[sectionIndex], {
              overflow: 'hidden',
            });
            if (!isMobile) {
              gsap.set(sections[1].querySelector('#rect-left'), {
                scaleY: 0,
              });
              gsap.set(sections[1].querySelector('#rect-right'), {
                scaleY: 0,
              });

              gsap.set(sections[sectionIndex].querySelector('.groupe__line'), {
                autoAlpha: 1,
              });
            }
            gsap.set(sections[sectionIndex].querySelector('.groupe__content'), {
              opacity: 0,
            });
            gsap.set(sections[sectionIndex].querySelector('.groupe__title'), {
              y: '-100px',
            });
            gsap.set(
              sections[sectionIndex].querySelector('.groupe__separator'),
              {
                '--lineScale': 0,
              }
            );
            gsap.set(sections[sectionIndex].querySelector('.groupe__text'), {
              y: '60px',
            });
            gsap.set(sections[sectionIndex].querySelector('.groupe__content'), {
              '--crochetLeftPosition': '-50px',
              '--crochetRightPosition': '50px',
            });
            gsap.set(sections[sectionIndex].querySelector('.groupe__numbers'), {
              y: '250px',
            });
            gsap.set(
              sections[sectionIndex].querySelectorAll('.groupe__numbers div'),
              {
                '--lineScale': 0,
              }
            );

            if (!isMobile) {
              tl.to(sections[1].querySelector('#rect-left'), {
                scaleY: 1,
                duration: 0.8,
              })
                .to(
                  sections[1].querySelector('#rect-right'),
                  {
                    scaleY: 1,
                    duration: 0.8,
                  },
                  '<'
                )
                .to(
                  sections[0].querySelector('.h-video__content'),
                  {
                    scale: 0.4,
                    duration: 0.8,
                  },
                  '<'
                )
                .to(sections[sectionIndex].querySelector('.groupe__line'), {
                  autoAlpha: 0,
                  duration: 0.8,
                  delay: 0.3,
                });
            }
            tl.to(
              sections[sectionIndex].querySelector('.groupe__content'),
              {
                opacity: 1,
                duration: 0.8,
                delay: 0.3,
              },
              '<'
            )
              .to(
                sections[sectionIndex].querySelector('.groupe__title'),
                {
                  y: '0px',
                  '--lineScale': 1,
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.groupe__text'),
                {
                  y: '0px',
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.groupe__separator'),
                {
                  '--lineScale': 1,
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.groupe__content'),
                {
                  '--crochetLeftPosition': '0px',
                  '--crochetRightPosition': '0px',
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.groupe__numbers'),
                {
                  y: '0px',
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll('.groupe__numbers div'),
                {
                  '--lineScale': 1,
                  duration: 0.8,
                },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      2: () => {
        timeline.to(sections[sectionIndex], {
          onStart: () => {
            const tl = gsap.timeline();
            gsap.set(sections[sectionIndex].querySelector('.pattern__right'), {
              x: isMobile ? '0%' : '100%',
            });
            gsap.set(sections[sectionIndex].querySelector('.pattern__left'), {
              x: isMobile ? '0%' : '-100%',
            });
            gsap.set(sections[sectionIndex].querySelector('.pattern__title'), {
              x: '-150px',
              opacity: 0,
            });
            gsap.set(sections[sectionIndex].querySelector('.groupe-2__text'), {
              x: '150px',
              opacity: 0,
            });
            gsap.set(
              sections[sectionIndex].querySelector(
                '.pattern__logo:not(.pattern__logo--mobile)'
              ),
              {
                opacity: 0,
                y: '0',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector(
                '.pattern__logo:not(.pattern__logo--mobile) > path'
              ),
              {
                fill: '#005050',
                strokeWidth: '2px',
              }
            );
            gsap.set(sections[sectionIndex].querySelector('.pattern__title'), {
              '--lineScale': 0,
            });

            tl.to(sections[sectionIndex].querySelector('.pattern__right'), {
              x: '0%',
              duration: 0.8,
            })
              .to(
                sections[sectionIndex].querySelector('.pattern__left'),
                { x: '0%', duration: 0.8 },
                '<'
              )
              .to(sections[sectionIndex].querySelector('.pattern__title'), {
                x: '0px',
                opacity: 1,
                duration: 0.6,
                delay: 0.3,
              })
              .to(
                sections[sectionIndex].querySelector('.groupe-2__text'),
                { x: '0px', opacity: 1, duration: 0.6 },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                { opacity: 1, duration: 0.6 },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.pattern__title'),
                {
                  '--lineScale': 1,
                  duration: 0.6,
                },
                '-=0.5'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });

            if (!reverse && !isMobile) {
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:first-of-type'
                ),
                {
                  y: !isMobile ? '80px' : '0',
                  opacity: 0,
                  visibility: 'hidden',
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(2)'
                ),
                {
                  y: !isMobile ? '45px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(3)'
                ),
                {
                  y: !isMobile ? '40px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(4)'
                ),
                {
                  y: !isMobile ? '15px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(5)'
                ),
                {
                  y: !isMobile ? '25px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(6)'
                ),
                {
                  y: !isMobile ? '40px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:last-of-type'
                ),
                {
                  y: !isMobile ? '65px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(sections[3].querySelectorAll('.pattern__title > img'), {
                y: '-75px',
                opacity: 0,
              });
              gsap.set(sections[3].querySelectorAll('.pattern__title'), {
                '--lineScale': 0,
              });
              gsap.set(
                [
                  sections[3].querySelectorAll('.marques__text'),
                  sections[3].querySelectorAll('.marques__left > a'),
                ],
                {
                  opacity: 0,
                }
              );
              gsap.set(sections[3].querySelectorAll('.marques__infos'), {
                y: '50px',
                opacity: 0,
              });
              gsap.set(
                sections[3].querySelectorAll(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                {
                  opacity: 0,
                }
              );
              gsap.set(sections[3].querySelectorAll('.marques__mask'), {
                height: '100%',
              });
            }
          },
        });
      },
      2.5: () => {
        timeline.to(sections[2], {
          onStart: () => {
            const tl = gsap.timeline();

            tl.to(sections[2].querySelector('.pattern__right'), {
              x: isMobile ? '0%' : '100%',
              duration: 0.8,
            })
              .to(
                sections[2].querySelector('.pattern__left'),
                {
                  x: isMobile ? '0%' : '-100%',
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[2].querySelector(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                { y: isMobile ? '0' : '72px', duration: 0.6 },
                '<'
              )
              .to(
                sections[2].querySelector(
                  '.pattern__logo:not(.pattern__logo--mobile) > path'
                ),
                { fill: '#fff', strokeWidth: '0', duration: 0.6 },
                '<'
              )
              .to(
                sections[3].querySelector(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                {
                  opacity: 1,
                  duration: 0,
                }
              );

            const totalDuration = tl.duration();
            timeline.to(sections[2], { duration: totalDuration });

            if (!isMobile) {
              gsap.set(document.querySelectorAll('.header__burger > span'), {
                backgroundColor: 'var(--white)',
              });
            }
          },
        });
      },
      3: () => {
        timeline.to(sections[sectionIndex], {
          onStart: () => {
            const tl = gsap.timeline();

            tl.to(
              sections[sectionIndex].querySelectorAll(
                '.pattern__logo:not(.pattern__logo--mobile)'
              ),
              {
                opacity: 1,
                duration: 0,
              }
            )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.marques__pagination > div > div'
                ),
                {
                  y: '0px',
                  opacity: 0.3,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.marques__pagination > div > div:first-of-type'
                ),
                {
                  visibility: 'visible',
                },
                '<'
              )

              .to(
                sections[sectionIndex].querySelectorAll(
                  '.pattern__title > img'
                ),
                {
                  y: 0,
                  opacity: 1,
                  duration: 0.8,
                  delay: 0.3,
                }
              )
              .to(
                sections[3].querySelectorAll('.pattern__title'),
                {
                  '--lineScale': 1,
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll('.marques__text'),
                {
                  x: 0,
                  opacity: 1,
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll('.marques__left > a'),
                {
                  opacity: 1,
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll('.marques__infos'),
                {
                  y: 0,
                  opacity: 1,
                  duration: 0.8,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll('.marques__mask'),
                {
                  height: '0',
                  duration: 0.8,
                },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      4: () => {
        timeline.to(sections[sectionIndex], {
          onStart: () => {
            const tl = gsap.timeline();
            gsap.set(sections[sectionIndex], {
              overflow: 'hidden',
            });
            gsap.set(
              sections[sectionIndex].querySelector('.valeurs__bg--left'),
              {
                y: isMobile ? '0%' : '-100%',
                borderRight: '0.5px solid var(--white)',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.valeurs__bg--right'),
              {
                y: isMobile ? '0%' : '100%',
                borderLeft: '0.5px solid var(--white)',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.valeurs__title, .valeurs__item > div'
              ),
              {
                opacity: 0,
              }
            );
            gsap.set(sections[sectionIndex].querySelector('.valeurs__title'), {
              '--lineScale': 0,
            });
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.valeurs__item:nth-child(1), .valeurs__item:nth-child(3)'
              ),
              {
                opacity: 0,
                y: '-170px',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.valeurs__item:nth-child(2), .valeurs__item:nth-child(4)'
              ),
              {
                opacity: 0,
                y: '170px',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.valeurs__item > h3, .valeurs__item > div, .valeurs__item > p'
              ),
              {
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll('.valeurs__item > h3'),
              {
                '--lineScale': 0,
              }
            );

            tl.to(sections[sectionIndex].querySelector('.valeurs__bg--left'), {
              y: '0%',
              duration: 0.8,
            })
              .to(
                sections[sectionIndex].querySelector('.valeurs__bg--right'),
                { y: '0%', duration: 0.8 },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.valeurs__bg--right, .valeurs__bg--left'
                ),
                {
                  y: '0%',
                  borderColor: 'transparent',
                  duration: 0.6,
                  delay: 0.3,
                }
              )
              .to(
                sections[sectionIndex].querySelectorAll('.valeurs__title'),
                {
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.valeurs__item:nth-child(1), .valeurs__item:nth-child(3)'
                ),
                {
                  y: '0px',
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.valeurs__item:nth-child(2), .valeurs__item:nth-child(4)'
                ),
                {
                  y: '0px',
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(sections[sectionIndex].querySelectorAll('.valeurs__title'), {
                '--lineScale': 1,
                duration: 0.6,
                delay: 0.3,
              })

              .to(
                sections[sectionIndex].querySelectorAll(
                  '.valeurs__item > h3, .valeurs__item > div, .valeurs__item > p'
                ),
                {
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll('.valeurs__item > h3'),
                {
                  '--lineScale': 1,
                  duration: 0.6,
                },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
          onComplete: () => {
            if (!isMobile) {
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:first-of-type'
                ),
                {
                  y: !isMobile ? '80px' : '0',
                  opacity: 0,
                  visibility: 'hidden',
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(2)'
                ),
                {
                  y: !isMobile ? '45px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(3)'
                ),
                {
                  y: !isMobile ? '40px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(4)'
                ),
                {
                  y: !isMobile ? '15px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(5)'
                ),
                {
                  y: !isMobile ? '25px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:nth-of-type(6)'
                ),
                {
                  y: !isMobile ? '40px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(
                sections[3].querySelector(
                  '.marques__pagination > div > div:last-of-type'
                ),
                {
                  y: !isMobile ? '65px' : '0',
                  opacity: 0,
                }
              );
              gsap.set(sections[3].querySelectorAll('.pattern__title > img'), {
                y: '-75px',
                opacity: 0,
              });
              gsap.set(sections[3].querySelectorAll('.pattern__title'), {
                '--lineScale': 0,
              });
              gsap.set(
                [
                  sections[3].querySelectorAll('.marques__text'),
                  sections[3].querySelectorAll('.marques__left > a'),
                ],
                {
                  opacity: 0,
                }
              );
              gsap.set(sections[3].querySelectorAll('.marques__infos'), {
                y: '50px',
                opacity: 0,
              });
              gsap.set(
                sections[3].querySelectorAll(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                {
                  opacity: 0,
                }
              );
              gsap.set(sections[3].querySelectorAll('.marques__mask'), {
                height: '100%',
              });
            }
          },
        });
      },
      4.5: () => {
        timeline.to(sections[4], {
          onStart: () => {
            const tl = gsap.timeline();

            tl.to(sections[4].querySelector('.valeurs__bg--left'), {
              y: '100%',
              duration: 0.8,
            })
              .to(
                sections[4].querySelector('.valeurs__bg--right'),
                { y: '-100%', duration: 0.8 },
                '<'
              )
              .to(
                sections[4].querySelectorAll('.valeurs__title, .valeurs__item'),
                {
                  opacity: 0,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[4].querySelectorAll('.valeurs__title'),
                {
                  '--lineScale': 0,
                  duration: 0.6,
                },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });

            if (!isMobile) {
              gsap.set(sections[5].querySelector('.histoire__header > h2'), {
                opacity: 0,
                '--lineScale': 0,
              });
              gsap.set(sections[5].querySelector('.histoire__header > div'), {
                opacity: 0,
                y: '160px',
              });
              gsap.set(sections[5].querySelector('.histoire__header > a'), {
                opacity: 0,
                y: '160px',
              });
              gsap.set(
                sections[5].querySelectorAll(
                  '.white-line > div, .black-line > div'
                ),
                {
                  opacity: 0,
                  scaleY: 0,
                  transformOrigin: 'top',
                }
              );
              gsap.set(
                sections[5].querySelectorAll(
                  '.histoire__content span, .histoire__content h3'
                ),
                {
                  opacity: 0,
                }
              );
            }
          },
        });
      },
      5: () => {
        timeline.to(sections[sectionIndex], {
          onStart: () => {
            const tl = gsap.timeline();

            if (isMobile) {
              gsap.set(sections[5].querySelector('.histoire__header > h2'), {
                opacity: 0,
                '--lineScale': 0,
              });
              gsap.set(sections[5].querySelector('.histoire__header > div'), {
                opacity: 0,
                y: '160px',
              });
              gsap.set(sections[5].querySelector('.histoire__header > a'), {
                opacity: 0,
                y: '160px',
              });
              gsap.set(
                sections[5].querySelectorAll(
                  '.white-line > div, .black-line > div'
                ),
                {
                  opacity: 0,
                  scaleY: 0,
                  transformOrigin: 'top',
                }
              );
              gsap.set(
                sections[5].querySelectorAll(
                  '.histoire__content span, .histoire__content h3'
                ),
                {
                  opacity: 0,
                }
              );
            }

            tl.to(
              sections[sectionIndex].querySelector('.histoire__header > h2'),
              {
                opacity: 1,
                duration: 0.6,
                '--lineScale': 1,
              }
            )
              .to(
                sections[sectionIndex].querySelector('.histoire__header > div'),
                {
                  opacity: 1,
                  y: '0px',
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.histoire__header > a'),
                {
                  opacity: 1,
                  y: '0px',
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.white-line > div, .black-line > div'
                ),
                {
                  opacity: 1,
                  scaleY: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.histoire__content span, .histoire__content h3'
                ),
                {
                  opacity: 1,
                  duration: 0.6,
                  delay: 0.5,
                }
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      6: () => {
        timeline.to(sections[sectionIndex], {
          autoAlpha: 1,
          onStart: () => {
            const tl = gsap.timeline();
            gsap.set(sections[sectionIndex], {
              overflow: 'hidden',
            });
            gsap.set(
              sections[sectionIndex].querySelector('.engagements__bg--left'),
              {
                y: isMobile ? '0%' : '-100%',
                borderRight: '0.5px solid var(--white)',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.engagements__bg--right'),
              {
                y: isMobile ? '0%' : '100%',
                borderLeft: '0.5px solid var(--white)',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.engagements__bg--right'),
              {
                y: isMobile ? '0%' : '100%',
                borderLeft: '0.5px solid var(--white)',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.engagements__content'),
              {
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.engagements__text > h2'),
              {
                y: '-100px',
                '--lineScale': 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector(
                '.engagements__text > div, .engagements__text > a'
              ),
              {
                y: '60px',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.engagements__text'),
              {
                '--crochetLeftPosition': '-50px',
                '--crochetRightPosition': '50px',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.engagements__items'),
              {
                y: '250px',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.engagements__items div'
              ),
              {
                '--lineScale': 0,
              }
            );

            tl.to(
              sections[sectionIndex].querySelector('.engagements__bg--left'),
              {
                y: '0%',
                duration: 0.8,
              }
            )
              .to(
                sections[sectionIndex].querySelector('.engagements__bg--right'),
                { y: '0%', duration: 0.8 },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.engagements__bg--right, .engagements__bg--left'
                ),
                {
                  y: '0%',
                  borderColor: 'transparent',
                  duration: 0.6,
                }
              )
              .to(
                sections[sectionIndex].querySelector('.engagements__content'),
                {
                  opacity: 1,
                  duration: 0.8,
                }
              )
              .to(
                sections[sectionIndex].querySelector('.engagements__text > h2'),
                {
                  y: '0px',
                  '--lineScale': 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.engagements__text > div, .engagements__text > a'
                ),
                {
                  y: '0px',
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.engagements__text'),
                {
                  '--crochetLeftPosition': '0px',
                  '--crochetRightPosition': '0px',
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.engagements__items'),
                {
                  y: '0px',
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.engagements__items div'
                ),
                {
                  '--lineScale': 1,
                  duration: 0.6,
                },
                '<'
              );
          },
          onComplete: () => {
            if (!isMobile) {
              gsap.set(sections[5].querySelector('.histoire__header > h2'), {
                opacity: 0,
                '--lineScale': 0,
              });
              gsap.set(sections[5].querySelector('.histoire__header > div'), {
                opacity: 0,
                y: '160px',
              });
              gsap.set(sections[5].querySelector('.histoire__header > a'), {
                opacity: 0,
                y: '160px',
              });
              gsap.set(
                sections[5].querySelectorAll(
                  '.white-line > div, .black-line > div'
                ),
                {
                  opacity: 0,
                  scaleY: 0,
                  transformOrigin: 'top',
                }
              );
              gsap.set(
                sections[5].querySelectorAll(
                  '.histoire__content span, .histoire__content h3'
                ),
                {
                  opacity: 0,
                }
              );
            }
          },
        });
      },
      6.5: () => {
        timeline.to(sections[6], {
          autoAlpha: 1,
          onStart: () => {
            const tl = gsap.timeline();

            tl.to(sections[6].querySelector('.engagements__bg--left'), {
              y: isMobile ? '0' : '100%',
              duration: 0.8,
            })
              .to(
                sections[6].querySelector('.engagements__bg--right'),
                { y: isMobile ? '0' : '-100%', duration: 0.8 },
                '<'
              )
              .to(
                sections[6].querySelector('.engagements__content'),
                {
                  opacity: 0,
                  duration: 0.6,
                },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      7: () => {
        timeline.to(sections[sectionIndex], {
          autoAlpha: 1,
          onStart: () => {
            const tl = gsap.timeline();
            gsap.set(sections[sectionIndex].querySelector('.pattern__right'), {
              x: isMobile ? '0%' : '100%',
            });
            gsap.set(sections[sectionIndex].querySelector('.pattern__left'), {
              x: isMobile ? '0%' : '-100%',
            });
            gsap.set(
              sections[sectionIndex].querySelector(
                '.pattern__logo:not(.pattern__logo--mobile)'
              ),
              {
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll('.pattern__title'),
              {
                x: '40px',
                opacity: 0,
                '--lineScale': 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.production__numbers > div:first-of-type > p:first-of-type, .production__numbers > div:last-of-type > p:first-of-type'
              ),
              {
                y: '-75px',
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.production__numbers > div:first-of-type > p:last-of-type, .production__numbers > div:last-of-type > p:last-of-type'
              ),
              {
                y: '75px',
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.production__numbers > div > div'
              ),
              {
                '--lineScale': 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector(
                '.production__map > div > svg #rect-mask'
              ),
              {
                width: '0',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.production__map > div > svg > circle, .production__map > div > svg .production__point'
              ),
              {
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelectorAll(
                '.production__map > div > svg .round, .production__map > div > svg .round-inner'
              ),
              {
                opacity: 0,
                scale: 0.4,
                transformOrigin: 'center center',
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.production__mapInfo'),
              {
                opacity: 0,
                y: '100px',
              }
            );

            tl.to(sections[sectionIndex].querySelector('.pattern__right'), {
              x: '0%',
              duration: 0.8,
            })
              .to(
                sections[sectionIndex].querySelector('.pattern__left'),
                { x: '0%', duration: 0.8 },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                { opacity: 1, duration: 0.6 },
                '<'
              )
              .to(sections[sectionIndex].querySelectorAll('.pattern__title'), {
                x: '0px',
                opacity: 1,
                duration: 0.6,
                delay: 0.3,
              })
              .to(
                sections[sectionIndex].querySelectorAll('.pattern__title'),
                {
                  '--lineScale': 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.production__numbers > div:first-of-type > p:first-of-type, .production__numbers > div:last-of-type > p:first-of-type, .production__numbers > div:first-of-type > p:last-of-type, .production__numbers > div:last-of-type > p:last-of-type',
                  '.production__numbers > div > div'
                ),
                {
                  y: '0px',
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.production__numbers > div > div'
                ),
                {
                  '--lineScale': 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.production__map > div > svg #rect-mask'
                ),
                {
                  width: '100%',
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.production__map > div > svg > circle, .production__map > div > svg .production__point'
                ),
                {
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll(
                  '.production__map > div > svg .round, .production__map > div > svg .round-inner'
                ),
                {
                  opacity: 1,
                  scale: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.production__mapInfo'),
                {
                  opacity: 1,
                  y: '0px',
                  duration: 0.6,
                },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      8: () => {
        timeline.to(sections[sectionIndex], {
          autoAlpha: 1,
          onStart: () => {
            const tl = gsap.timeline();
            gsap.set(sections[sectionIndex].querySelector('.pattern__right'), {
              x: isMobile ? '0%' : '100%',
            });
            gsap.set(sections[sectionIndex].querySelector('.pattern__left'), {
              x: isMobile ? '0%' : '-100%',
            });
            gsap.set(
              sections[sectionIndex].querySelector(
                '.pattern__logo:not(.pattern__logo--mobile)'
              ),
              {
                opacity: 0,
              }
            );
            gsap.set(sections[sectionIndex].querySelector('.pattern__title'), {
              x: '150px',
              opacity: 0,
              '--lineScale': 0,
            });
            gsap.set(
              sections[sectionIndex].querySelector('.pattern__left > a'),
              {
                x: '150px',
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.nous-rejoindre__text'),
              {
                x: '150px',
                opacity: 0,
              }
            );
            gsap.set(
              sections[sectionIndex].querySelector('.nous-rejoindre__numbers'),
              {
                y: '100px',
                opacity: 0,
              }
            );

            tl.to(sections[sectionIndex].querySelector('.pattern__right'), {
              x: '0%',
              duration: 0.8,
            })
              .to(
                sections[sectionIndex].querySelector('.pattern__left'),
                { x: '0%', duration: 0.8 },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                { opacity: 1, duration: 0.6, delay: 0.3 }
              )

              .to(
                sections[sectionIndex].querySelectorAll('.pattern__title'),
                {
                  x: '0px',
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelectorAll('.pattern__title'),
                {
                  '--lineScale': 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.pattern__left > a'),
                {
                  x: '0px',
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.nous-rejoindre__text'),
                {
                  x: '0px',
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.nous-rejoindre__numbers'
                ),
                {
                  y: '0px',
                  opacity: 1,
                  duration: 0.6,
                },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      8.5: () => {
        timeline.to(sections[8], {
          autoAlpha: 1,
          onStart: () => {
            const tl = gsap.timeline();

            gsap.set(
              sections[8].querySelector(
                '.pattern__logo:not(.pattern__logo--mobile)'
              ),
              {
                opacity: 0,
              }
            );

            tl.to(sections[8].querySelector('.pattern__right'), {
              x: isMobile ? '0%' : '100%',
              duration: 0.8,
            })
              .to(
                sections[8].querySelector('.pattern__left'),
                { x: isMobile ? '0%' : '-100%', duration: 0.8 },
                '<'
              )
              .to(
                sections[8].querySelector(
                  '.pattern__logo:not(.pattern__logo--mobile)'
                ),
                { opacity: 0, duration: 0 },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });

            gsap.set(sections[9], {
              opacity: 1,
            });
            gsap.set(sections[9].querySelector('.footer__img > a'), {
              opacity: 0,
              y: '70px',
            });
            gsap.set(sections[9].querySelector('.footer__img > svg'), {
              x: '35%',
            });
            gsap.set(sections[9].querySelector('.footer__img > svg > g'), {
              opacity: 0,
            });
            gsap.set(
              sections[9].querySelector(
                '.footer__img > svg > g > path:first-of-type'
              ),
              {
                x: '-150px',
              }
            );
            gsap.set(
              sections[9].querySelector(
                '.footer__img > svg > g > path:last-of-type'
              ),
              {
                x: '-310px',
              }
            );
          },
        });
      },
      9: () => {
        timeline.to(sections[sectionIndex], {
          autoAlpha: 1,
          onStart: () => {
            CustomEase.create('custom', 'M0,0 C0.126,0.382 0.277,1 1,1');
            const tl = gsap.timeline();

            tl.to(sections[sectionIndex].querySelector('.footer__img > svg'), {
              x: '0%',
              duration: 0.8,
              ease: 'custom',
            })
              .to(
                sections[sectionIndex].querySelector('.footer__img > svg > g'),
                {
                  opacity: 1,
                  duration: 0.8,
                  ease: 'custom',
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.footer__img > svg > g > path:first-of-type'
                ),
                {
                  x: '0px',
                  duration: 0.8,
                  ease: 'custom',
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector(
                  '.footer__img > svg > g > path:last-of-type'
                ),
                {
                  x: '0px',
                  duration: 0.8,
                  ease: 'custom',
                },
                '<'
              )
              .to(
                sections[sectionIndex].querySelector('.footer__img > a'),
                { opacity: 1, duration: 0.8, y: '0px', ease: 'custom' },
                '<'
              );

            const totalDuration = tl.duration();
            timeline.to(sections[sectionIndex], { duration: totalDuration });
          },
        });
      },
      // Define animations for other sections as needed
    };

    animations[sectionIndex]?.(); // Execute the animation for the targeted section
  });
}

function handleDirection() {
  if (!listening) return;

  if (isMobile) return;

  listening = false;
  const goingBackwards = next < current;

  if (direction === 'down' && next < sections.length - 1) {
    switch (current) {
      case 2:
        queueAnimation(2.5);
        next++;
        queueAnimation(next, goingBackwards);
        break;
      case 4:
        queueAnimation(4.5);
        next++;
        queueAnimation(next, goingBackwards);
        break;
      case 8:
        queueAnimation(8.5);
        next++;
        queueAnimation(next, goingBackwards);
        break;
      default:
        next++;
        queueAnimation(next, goingBackwards);
    }
  } else if (direction === 'up' && next > 0) {
    if (current === 1) {
      queueAnimation(0.5, true);
      next--;
      queueAnimation(next, goingBackwards);
    } else if (current === 4) {
      queueAnimation(4.5, true);
      next--;
      queueAnimation(next, goingBackwards);
    } else if (current === 6) {
      queueAnimation(6.5, true);
      next--;
      queueAnimation(next, goingBackwards);
    } else {
      next--;
      queueAnimation(next, goingBackwards);
    }
  } else {
    listening = true;
    return;
  }
}

sections[0].querySelector('.h-video > span').addEventListener('click', () => {
  if (current === 0) {
    next++;
    queueAnimation(next);
  }
});
